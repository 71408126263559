import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Span, Button, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"aanbod"} />
		<Helmet>
			<title>
				dimple - Aanbod
			</title>
			<meta name={"description"} content={"Je kan bij Dimple  terecht voor: \n- Flow-, informatie- en behoeftenanalyse\n- Ontwikkeling van digitale werkplek op maat\n- Coaching, training en nazorg"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/dimple-d-white-32.png?v=2023-06-07T13:08:36.406Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.MenuHam>
			<Override slot="image" />
			<Override slot="linkBox" />
		</Components.MenuHam>
		<Section padding="20px 0 20px 0" sm-padding="60px 0 60px 0" background="#ffffff">
			<Text margin="0px 0px 0px 0px" font="--headline2" text-align="center">
				Aanbod
			</Text>
			<Text margin="0px 0px 0px 0px" />
		</Section>
		<Section padding="20px 0 20px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text
						margin="0px 0px 11px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						text-align="center"
					>
						Flow-, informatie- en behoeftenanalyse
					</Text>
					<Image src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_thought_process_re_om58.svg?v=2023-05-23T16:32:07.367Z" display="block" max-width="100%" margin="10px 0px 10px 0px" />
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						{" "}Hoe creëer jij waarde of impact voor jouw klanten?  Welke informatie en processen zijn hierbij cruciaal?  Hoe houd je overzicht over alles wat gaande is?   Hoe blijf je op elkaar afgestemd?  Wat loopt goed?  Wat moet beter?  Aan welke{" "}
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							vereisten
						</Span>
						{" "}en randvoorwaarden moet een digitale werkplek minimaal voldoen?{"  "}
					</Text>
					<Button type="link" href="/aanbod/analyse" target="_top" text-decoration-line="initial">
						Meer weten?
					</Button>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text
						margin="0px 0px 11px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						text-align="center"
					>
						Ontwikkeling van digitale werkplek op maat{"\n\n"}
					</Text>
					<Image src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_project_team_lc5a.svg?v=2023-05-24T09:30:49.745Z" display="block" max-width="100%" margin="50px 0px 10px 0px" />
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Zoek je als team of organisatie digitale ondersteuning voor een productievere en creatievere samenwerking?
						<br />
						{" "}Of ben je als freelancer, professional of individu op zoek naar overzicht en structuur van alle digitale informatie die voor jou waarde of belang is?
					</Text>
					<Button type="link" text-decoration-line="initial" href="/aanbod/ontwikkeling" target="_top">
						Meer weten?
					</Button>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text
						margin="0px 0px 11px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						text-align="center"
					>
						Coaching, training en nazorg{"\n\n"}
					</Text>
					<Image
						src="https://uploads.quarkly.io/646a3bab16e21c0024b31727/images/undraw_teaching_re_g7e3.svg?v=2023-05-24T12:38:55.979Z"
						display="block"
						max-width="100%"
						margin="35px 0px 10px 0px"
						max-height="255px"
					/>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						We helpen jou(w team) om productiever te worden, mét meer rust in ieders hoofd.  We bieden training-op-maat én individuele coaching.  Ook blijven we bereikbaar voor ondersteuning, aanpassingen of uitbreidingen aan je digitale werkplek.{"\n\n"}
					</Text>
					<Button type="link" href="/aanbod/ondersteuning" target="_top" text-decoration-line="initial">
						Meer weten?
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Troeven>
			<Override slot="text4" />
			<Override slot="text7" />
			<Override slot="text5" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="text" />
			<Override slot="box" />
		</Components.Troeven>
		<Components.Contact2 />
		<Components.Bottom>
			<Override slot="text" font="--base">
				<Link
					href="https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					Algemene voorwaarden
				</Link>
				<Link
					href="https://dimple.be/DISCLAIMER PRIVACY DIMPLE.pdf"
					font="--lead"
					color="--primary"
					text-decoration-line="initial"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					Website disclaimer & privacy
				</Link>
				<Link
					href="https://quarkly.io/preview#/https://dimple.be/ALGEMENE%20VERKOOP-%20EN%20FACTUURVOORWAARDEN%20-%20DIMPLE.pdf"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
				</Link>
				(c) 2024 Dimple{"\n\n"}
			</Override>
		</Components.Bottom>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"646a3bab16e21c0024b31725"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<meta
				charset={""}
				name={"viewport"}
				content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
				place={"endOfHead"}
				rawKey={"6471fb35a048276ccf953797"}
			/>
			<script async={false} src={""} place={"endOfHead"} rawKey={"6480503d4a1beafc87993190"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n  gtag('config', 'G-8CGV73MC0F')"}
			</script>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-8CGV73MC0F"} place={"endOfHead"} rawKey={"64805dac61988253a2113525"} />
		</RawHtml>
	</Theme>;
});